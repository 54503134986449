<template>
  <div>
    <form-title :text="$t('title.select_decade')" />
    <form-content>
      <div class="flex flex-wrap -mx-1 mb-6">
        <div
          class="px-1 mb-2 w-4/12 sm:w-2/12 lg:w-3/12"
          v-for="item in decades"
          :key="item"
        >
          <btn-choose
            type="button"
            @clicked="$emit('update-user-data', { birthDecade: item })"
            v-text="item"
          />
        </div>
      </div>
    </form-content>
  </div>
</template>

<script>
import BtnChoose from "../Ui/BtnChoose.vue";
import FormContent from "./FormContent.vue";
import FormTitle from "./FormTitle.vue";
export default {
  components: { FormTitle, BtnChoose, FormContent },
  name: "SelectDecade",

  methods: {
    populateDecadesArray(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, index) => {
          if (index % 10 === 0) {
            return start + index;
          }
          return;
        })
        .filter((item) => item);
    },
  },
  computed: {
    decades() {
      return this.populateDecadesArray(1920, 2020);
    },
  },
};
</script>
